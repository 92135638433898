<template>
    <div class="weight">
        <breadCrumb :siderContent="siderName" />
        <div class="use myForm">
            <span class="kai">商品列表显示模式:</span>
            <a-radio-group v-model="form.model_type">
                <a-radio  :value="1">正常模式</a-radio>
                <a-radio  :value="2">大字模式</a-radio>
            </a-radio-group>
        </div>
        <div class="use myForm">
            <span class="kai">收银模式:</span>
            <a-radio-group v-model="form.cashier_mode_type">
                <a-radio  :value="1">普通收银</a-radio>
                <a-radio  :value="2">快速收银</a-radio>
            </a-radio-group>
        </div>
        <div class="use myForm">
            <span class="kai">商品显示列数:</span>
            <a-input-number v-model="form.column_num" :min="1"/>
        </div>
        <div  class="yv">
            <div>预览图:</div>  
            <div class="preview">
                <div class="top"></div>
                <div class="bottom">
                    <div class="first"></div>
                    <div class="second"></div>
                    <div class="third" v-if="form.cashier_mode_type==1"></div>
                    <div :class="[form.cashier_mode_type==1 ? 'fourth' : 'quitYin' ]">
                        <div class="fourthTop"></div>
                        <div  class="fourthBottom">
                            <div 
                                v-for="(count,index) in count" :key="index" 
                                class="every"
                                :style="'width:'+(form.cashier_mode_type==1?(40.3-form.column_num*0.8):(46.5-form.column_num*0.8))/form.column_num+'vw;height:' + (form.model_type==2 ?62.4 : 80.5) / form.column_num+ 'vh'"

                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="searchForm">
            <a-button @click="onSubmit" class="searchBtn">保存设置</a-button >
        </div> 
    </div>
</template>
<script>
import { screenSetting,getScreenSetting} from '@/request/business.js'
import breadCrumb from "@/components/BreadCrumb/BreadCrumb";
import { mapState , mapActions , mapMutations } from "vuex";
export default {
    data() {
        return {
            siderName:"商品模式设置",
            form:{
                model_type:1,
                cashier_mode_type:1,
                column_num:6,
            },
            columns:6,
            count:1000,       
        }
    },
    components: {
        breadCrumb
    },
    computed: {
        ...mapState(["topTabList"]),
    },
    mounted() {
        this.getDetail()
    },
    methods: {
        ...mapActions(['updateTabListAction']),
        ...mapMutations(['updateCashier']),
        onSubmit(){
            const parmas={
                "key": "goods_model",
                "form":this.form
            }
            if(/^\+?[1-9]\d*$/.test(this.form.column_num)){
                screenSetting(parmas).then(res => {
                    if(res.data.status === 200){
                        this.$message.success('保存设置成功')
                        let list = JSON.parse(JSON.stringify(this.topTabList))
                        list.forEach(item => {
                            if(item.title == '收银'){
                                item.path = this.form.cashier_mode_type == 1 ? '/yingye' : '/quickCashier'
                                item.name = this.form.cashier_mode_type == 1 ? 'Yingye' : 'quickCashier'
                            }
                        })
                        this.updateTabListAction(list)
                        this.updateCashier(this.form.cashier_mode_type)
                    }
                })
            }else{
                this.$message.error('商品显示列数为大于0的正整数')
            }
            
            
        },
        getDetail(){
            getScreenSetting({key:'goods_model'}).then(res => {
                if(res.data.status === 200){
                    const values=res.data.data.values;
                    this.form.model_type = values.model_type || 1
                    this.form.cashier_mode_type = values.cashier_mode_type || 1
                    this.form.column_num=values.column_num || 6
                }
            })
        }
    },
};
</script>
<style lang="less" scoped>
.weight{
    width: 100%;
    background: #f8f8ff;
    .use{
        padding-left:1.6vh ;
        .kai{
            display: inline-block;
            margin-right:0.5vw;
            margin-top:3.3vh;
            margin-bottom: 3.2vh;
            font-size: 1.8vh;
            width: 12vw;
            text-align: right;
        }
    }
    .install{
        font-size: 2vh;
        line-height: 3.1vh;
        margin-bottom: 2.7vh;
        display: flex;
        .heng{
            height:1px;
            width:2vw;
            background:#d0d0d0 ;
            line-height: 3.1vh;
            margin-top: 1.5vh;
        }
        .font{
            width:6.25vw;
            text-align: center;
            margin-left: 1.6vh;
        }
        .kuan{
            width:75vw !important;
        }
    }
    .ant-form{
        padding-left:1.6vh ;
    }
    .searchForm{
        margin-top:10vh;
        margin-left: 2vw;
    }
    /deep/.ant-form-item{
        display: flex;
        width:80vw;
        margin-bottom: 1.6vh;
        label{
            color:#505050;
        }
    }
    /deep/.ant-form-item-control-wrapper{
        width:19vw;
    }
    /deep/.ant-radio-wrapper{
        span{
            font-size: 1.8vh;
        }
        /deep/.ant-form-item-required{
            margin-right: 2vh;
        }  
    }
}
.yv{
    display: flex;
    padding-left: 1.6vh;
    font-size: 1.8vh;
}
.preview{
    padding-left: 2vw;
    .top{
        background: #c73e3e;
        width:69.8vw;
        height:3.3vh;
    }
    .bottom{
        display: flex;
        height:66.7vh;
        background: #f5f5f5;
        width:69.8vw;
        .first{
            width:4.7vw;
            background: rgba(59, 59, 69, 1);
           
        }
        .second{
            width:17.6vw;
            background: #fff;
             margin-right:0.2vw;
        }
        .third{
            width:6.2vw;
            background: #fff;
            
        }
        .quitYin{
            width:47.3vw;
            .fourthTop{
                height:4.4vh;
                background: #fff;
                margin-top:0.65vh;
                margin-left: 0.59vw;
                margin-right: 0.59vw;
            }
            .fourthBottom{
                display: flex;
                flex-wrap: wrap;
                margin-right:0.8vw;
                overflow: hidden;
                height: 61.65vh;
                .every{
                    background: #2a82e4;
                    margin-top: 0.8vh;
                    margin-left:0.8vw;
                    opacity: 0.1;
                }
            }
        }
        .fourth{
            width:41.1vw;
            .fourthTop{
                height:4.4vh;
                background: #fff;
                margin-top:0.65vh;
                margin-left: 0.59vw;
                margin-right: 0.59vw;
            }
            .fourthBottom{
                display: flex;
                flex-wrap: wrap;
                margin-right:0.8vw;
                overflow: hidden;
                height: 61.65vh;
                .every{
                    background: #2a82e4;
                    margin-top: 0.8vh;
                    margin-left:0.8vw;
                    opacity: 0.1;
                }
            }
        }
    }
}
</style>
